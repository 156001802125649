import {
  applyActionCode,
  checkActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  GithubAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
} from "firebase/auth";

import { auth } from "@/services/client/firebase";
import { verifyPasswordResetCode } from "@firebase/auth";

export default class AuthUserService {
  static async _getSignInMethod(email: string) {
    return fetchSignInMethodsForEmail(auth, email).then((methods) => {
      return methods;
    });
  }

  static async checkEmailExists(email: string) {
    const methods = await this._getSignInMethod(email);
    return methods.length > 0;
  }

  static async signUpWithEmail({
    username,
    email,
    password,
  }: {
    username: string;
    email: string;
    password: string;
  }) {
    return createUserWithEmailAndPassword(auth, email, password).then(
      async (userCreds) => {
        const user = userCreds.user;
        await updateProfile(user, {
          displayName: username,
          photoURL: `https://ui-avatars.com/api/?name=${username}&size=128&rounded=true`,
        });
        return user;
      }
    );
  }

  static async signInWithEmail({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    return signInWithEmailAndPassword(auth, email, password).then(
      (userCreds) => {
        return userCreds.user;
      }
    );
  }

  static async signInWithGitHub() {
    const githubProvider = new GithubAuthProvider();
    return signInWithPopup(auth, githubProvider).then((result) => {
      const credential = GithubAuthProvider.credentialFromResult(result)!;
      const token = credential.accessToken;
      const user = result.user;
      return user;
    });
  }

  static async signOut() {
    return signOut(auth);
  }

  static async updateProfileForCurrentUser({
    username,
    photoURL,
  }: {
    username?: string;
    photoURL?: string;
  }) {
    return updateProfile(auth.currentUser!, {
      displayName: username,
      photoURL,
    });
  }

  static async refreshToken() {
    return auth.currentUser?.getIdToken(true);
  }

  static async getUserToken() {
    const idTokenResult = await auth.currentUser?.getIdTokenResult();
    const claims = idTokenResult?.claims;
    let token = idTokenResult?.token;
    const expiration = claims?.exp;
    if (expiration) {
      const nowTS = new Date().getTime();
      const timeLeft = nowTS / 1000 - parseInt(expiration);
      if (timeLeft >= 0) {
        token = await auth.currentUser?.getIdToken(true);
      }
    }
    return token;
  }

  static async verifyEmail() {
    return sendEmailVerification(auth.currentUser!);
  }

  static async checkActionCode(code: string) {
    return checkActionCode(auth, code);
  }

  static async applyActionCode(code: string) {
    return applyActionCode(auth, code);
  }

  static async resetPasswordEmail(email: string) {
    return sendPasswordResetEmail(auth, email);
  }

  static async verifyPasswordResetCode(code: string) {
    return verifyPasswordResetCode(auth, code);
  }

  static async confirmPasswordReset(code: string, password: string) {
    return confirmPasswordReset(auth, code, password);
  }
}
