import { useState, useEffect } from "react";
import { remark } from "remark";
import remarkHTML from "remark-html";

import styles from "./Markdown.module.css";

const RemarkComponent = ({ content }: { content: string }) => {
  const [renderer, setRenderer] = useState("");

  useEffect(() => {
    async function render() {
      const processContent = await remark().use(remarkHTML).process(content);
      setRenderer(processContent.toString());
    }

    render();
  });

  return (
    <span
      className={styles["markdown-body"]}
      dangerouslySetInnerHTML={{ __html: renderer }}
    ></span>
  );
};

export default RemarkComponent;
