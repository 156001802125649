import { ReactNode } from "react";
import Head from "next/head";

import Header from "@/components/common/Header";
import Footer from "@/components/common/Footer";

type Props = {
  children?: ReactNode;
};

export default function Layout({ children }: Props) {
  return (
    <>
      <Head>
        <title>Prompt Hacker - PromptLabs</title>
        <meta name="description" content="Prompt Hacker" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Header />
      <main className="w-full">
        <div className="max-w-7xl mx-auto p-6 px-10">{children}</div>
      </main>
      <Footer />
    </>
  );
}
