import clsx from "clsx";
import Link from "next/link";
import Image from "next/image";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { useAuth } from "@/context/auth/AuthUserContext";
import AuthUserService from "@/services/client/AuthUser";
import logo from "@/../public/logo.svg";

const HeaderComponent = () => {
  const { authUser, loading } = useAuth();

  const handleSignOut = async () => {
    await AuthUserService.signOut();
  };

  const CurrentUser = () => {
    const currentUser = authUser!;
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 text-sm font-semibold text-gray-900">
            {currentUser.displayName}
            <ChevronDownIcon
              className="-mr-1 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {authUser?.role === "admin" ? (
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      href="/admin"
                      className={clsx(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                    >
                      Admin
                    </Link>
                  )}
                </Menu.Item>
              ) : (
                ""
              )}
              <Menu.Item>
                {({ active }) => (
                  <Link
                    href="/profile"
                    className={clsx(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Account settings
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={clsx(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block w-full px-4 py-2 text-left text-sm"
                    )}
                    onClick={handleSignOut}
                  >
                    Sign out
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const SignInButton = () => {
    return (
      <Link
        href="/signin"
        className="text-sm md:text-xs font-semibold leading-6 text-gray-900"
      >
        Sign in<span aria-hidden="true">&rarr;</span>
      </Link>
    );
  };

  return (
    <>
      <header className="w-full border-slate-900/10 border-b bg-white">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-5 px-8"
          aria-label="Global"
        >
          <div className="flex-1 hidden md:flex">
            <Link href="/" className="-m-1.5 p-1.5">
              <Image src={logo} alt="Prompt Labs" height={50} />
              <span className="font-bold text-xl indent-[-9999em] sm:hidden">
                Prompt Labs
              </span>
            </Link>
          </div>
          <div className="flex gap-x-8">
            <Link
              href="/"
              className="text-xs md:text-sm leading-6 text-gray-900"
            >
              Home
            </Link>
            <Link
              href="/challenges"
              className="text-xs md:text-sm leading-6 text-gray-900"
            >
              Challenges
            </Link>
            <Link
              href="/leaderboard"
              className="text-xs md:text-sm leading-6 text-gray-900"
            >
              Leaderboard
            </Link>
            <Link
              href="/about"
              className="text-xs md:text-sm leading-6 text-gray-900"
            >
              About
            </Link>
          </div>
          <div className="flex flex-1 justify-end">
            {authUser ? <CurrentUser /> : <SignInButton />}
          </div>
        </nav>
      </header>
    </>
  );
};

export default HeaderComponent;
