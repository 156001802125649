import Link from "next/link";
import { useAuth } from "@/context/auth/AuthUserContext";

const FooterComponent = () => {
  const { authUser } = useAuth();
  return (
    <footer className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-12 md:flex lg:px-8">
        <div className="mt-8 md:mt-0">
          <p className="text-left text-blue-500">
            {authUser ? (
              <Link
                href="https://github.com/promptlabscc/feedback/issues/new/choose"
                target="_blank"
              >
                Give Feedback
              </Link>
            ) : (
              ""
            )}
          </p>
          <p className={"mt-4"}>
            <Link
              href="https://www.producthunt.com/posts/prompt-hackers-mvp?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-prompt&#0045;hackers&#0045;mvp"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=391773&theme=light"
                alt="Prompt&#0032;Hackers&#0032;MVP - Solves&#0032;real&#0045;world&#0032;engineering&#0032;challenges&#0032;with&#0032;ChatGPT | Product Hunt"
                style={{ width: "250px", height: "54px" }}
                width="250"
                height="54"
              />
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
