import _pick from "lodash/pick";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import type { Challenge } from "@/types/challenge";
import { db } from "@/services/client/firebase";

export default class ChallengeService {
  static COLLECTION_NAME = "challenges";
  static coll = collection(db, this.COLLECTION_NAME);

  static _toChallenge(doc: any) {
    const data = doc.data();
    return {
      ...data,
      id: doc.id,
      createdAt: data.createdAt.toDate(),
    } as Challenge;
  }

  static async listPublished() {
    const q = query(this.coll, where("isPublished", "==", true));
    const snapshot = await getDocs(q);
    return snapshot.docs.map((e) => {
      return this._toChallenge(e);
    });
  }

  static async listAll() {
    const snapshot = await getDocs(this.coll);
    return snapshot.docs.map((e) => {
      return this._toChallenge(e);
    });
  }

  static async create(challenge: Challenge) {
    const ret = await addDoc(this.coll, {
      seq: challenge.seq,
      slug: challenge.slug,
      title: challenge.title,
      description: challenge.description,
      content: challenge.content,
      difficulty: challenge.difficulty,
      challengers: challenge.challengers,
      attempts: challenge.attempts,
      averageScore: challenge.averageScore,
      isPublished: challenge.isPublished,
      createdAt: challenge.createdAt,
    });
    return ret;
  }

  static async getByID(id: string) {
    const ref = doc(db, this.COLLECTION_NAME, id);
    const qs = await getDoc(ref);
    if (!qs.exists()) {
      return null;
    } else {
      return this._toChallenge(qs);
    }
  }

  static async getBySlug(slug: string) {
    const q = query(this.coll, where("slug", "==", slug));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty || querySnapshot.size === 0) {
      return null;
    }
    const rv = querySnapshot.docs[0];
    return this._toChallenge(rv);
  }

  static async getLatest() {
    const q = query(this.coll, orderBy("createdAt", "desc"), limit(1));
    const qs = await getDocs(q);
    if (qs.empty || qs.size === 0) {
      return null;
    }
    const doc = qs.docs[0];
    return this._toChallenge(doc);
  }

  static async listAllSlugs() {
    const cs = await this.listAll();
    return cs.map((each: any) => {
      return each.slug;
    });
  }

  static async update(
    id: string,
    updateData: {
      title: string;
      seq: number;
      slug: string;
      description: string;
      content: string;
      isPublished: boolean;
      difficulty: string;
    }
  ) {
    const ref = doc(db, this.COLLECTION_NAME, id);
    const ret = await updateDoc(
      ref,
      _pick(updateData, [
        "title",
        "seq",
        "slug",
        "description",
        "content",
        "isPublished",
        "difficulty",
      ])
    );
    return ret;
  }
}
